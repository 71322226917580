import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { TableColumn, TableComponent } from "../components";

export type DataToArray = () => any[][];

export type ExportType = 'excel' | 'csv' | 'tsv' | 'json' | 'clipboard';

export interface ExportToXlsx {
  export(
    name: string,
    table: TableComponent | HTMLElement | DataToArray,
    rows: (() => Promise<any[]>)[],
    extras: Partial<TableColumn>[],
    onlyVisible: boolean,
    mode: string
  ): Promise<void>;
}

@Injectable({
  providedIn: "root",
})
export class ExportLoaderService {
  constructor(private translateService: TranslateService) {}

  async export(
    table: TableComponent | HTMLElement,
    rows: (() => Promise<any[]>)[],
    extras: Partial<TableColumn>[],
    onlyVisible = false,
    mode: ExportType = 'excel',
    name: string = "table",
    progress?: (value: number) => void,
    exportOptions?: any
  ): Promise<void> {
    const { ExcelExportService } = await import(
      /* webpackChunkName: 'excel' */ "src/app/modules/excel/excel-export.service"
    );
    const exportService = new ExcelExportService(this.translateService);
    await exportService.export(table?.id || name, table, rows, extras, onlyVisible, mode, progress, exportOptions);
  }
}
