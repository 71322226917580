import { Injectable } from "@angular/core";
import { ActiveState, EntityState, EntityStore, QueryEntity, StoreConfig } from "@datorama/akita";
import { ListResultDto, newAddress, newCustomerContact, ResourceLeaveDto, ResourceMasterDto, ResourceVisitDto, SearchDto } from "@shared/models";
import { combineLatest, Observable } from "rxjs";
import { auditTime, filter, switchMap, take, tap } from "rxjs/operators";
import { DataService, ServiceConfig } from "../";
import { LoginStatus } from "../app.store";
import { ListDataService } from "./list-data.service";

export interface AppointmentCounts { [dt: string]: number; }
export interface ResourceMasterState extends EntityState<ResourceMasterDto>, ActiveState { }

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "resourceMaster", idKey: "code" })
export class ResourceMasterStore extends EntityStore<ResourceMasterState, ResourceMasterDto> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: "root" })
export class ResourceMasterQuery extends QueryEntity<ResourceMasterState, ResourceMasterDto> {
  $error = this.selectError();

  constructor(protected store: ResourceMasterStore) {
    super(store);
  }
}

/**
 * TODO: Re-look at how we are using the 'stored' resource, if this is still neccesary
 */
@Injectable({
  providedIn: "root"
})
export class ResourceService extends ListDataService {
  $resources: Observable<ResourceMasterDto[]>;
  $loading: Observable<boolean>;
  $currentResource: Observable<ResourceMasterDto>;

  get resources(): ResourceMasterDto[] {
    return this.query.getAll();
  }

  get currentResource(): ResourceMasterDto {
    return this.query.getActive();
  }

  set currentResource(next: ResourceMasterDto) {
    if (next) {
      this.store.setActive(next.code);
    }
  }

  constructor(config: ServiceConfig, private store: ResourceMasterStore, private query: ResourceMasterQuery, dataService: DataService) {
    super(config, dataService);
    this.$resources = this.query.selectAll();
    this.$loading = this.query.selectLoading();
    this.$currentResource = this.query.selectActive();
    this.store.setLoading(false);
    this.getResourceObservable()
    .subscribe(this.applyResourcesToStore());
  }

  selectResource(resourceId: string): ResourceMasterDto {
    return this.query.getEntity(resourceId);
  }

  private getResourceObservable() {
    // use same observable operations as in the User.Service
    return combineLatest([this.appQuery.$tenant2.pipe(auditTime(10)), this.appQuery.$loginStatus])
    .pipe(
      this.notDisposed(),
      filter(([t, l]) => !!t && l === LoginStatus.True),
      tap(() => {
        this.store.setLoading(true);
        this.store.remove();
      }),
      switchMap(() => this.getResources())
    );
  }

  private applyResourcesToStore(): (list: ResourceMasterDto[]) => void {
    return list => {
      this.store.set(list);
      if (!this.currentResource) {
        let curr = this.appQuery.defaultResource;
        if ((!curr || curr === "*") && list.length) {
          curr = list[0].code;
        }
        // this.store.setActive(curr);
        // dont select the first resource here... this can be done in the component that integrates the service
      }
      this.store.setLoading(false);
    };
  }

  /**
   * Refresh Resource store with new Data (once)
   */
  public updateResourcesStore() {
    this.getResourceObservable()
    .pipe(take(1))
    .subscribe(this.applyResourcesToStore());
  }

  searchResources(query: SearchDto): Observable<ListResultDto<ResourceMasterDto>> {
    return this.searchList<ResourceMasterDto>('ResourceMaster', query);
  }

  getResources(): Observable<ResourceMasterDto[]> {
    return this.http.get<ResourceMasterDto[]>("values/list/ResourceMaster");
  }

  getResource(resourceId: string, full = false): Observable<ResourceMasterDto> {
    const post = full ? "/full" : "";
    return this.http.get<ResourceMasterDto>(`resources/${this.safeEncode(resourceId)}${post}`);
  }

  getVisits(resourceId: string, date: Date) {
    resourceId = this.safeEncode(resourceId);
    return this.http.get<ResourceVisitDto[]>(`resources/${resourceId}/visits/${date.format()}`);
  }

  getAppointments(resourceId: string, date: Date): Observable<AppointmentCounts> {
    resourceId = this.safeEncode(resourceId);
    return this.http.get<AppointmentCounts>(`resources/${resourceId}/appointments/${date.format()}`);
  }

  newVisit(resourceId: string, visit: ResourceVisitDto) {
    return this.http.post<ResourceVisitDto>(`resources/${resourceId}/visits`, visit);
  }

  updateVisit(resourceId: string, visit: ResourceVisitDto) {
    return this.http.put<ResourceVisitDto>(`resources/${resourceId}/visits`, visit);
  }

  deleteVisit(resourceId: string, visitId: string) {
    resourceId = this.safeEncode(resourceId);
    return this.http.delete(`resources/${resourceId}/visits/${visitId}`);
  }

  getLeave(resourceId: string): Observable<ResourceLeaveDto[]> {
    resourceId = this.safeEncode(resourceId);
    return this.http.get<ResourceLeaveDto[]>(`resources/${resourceId}/leave`);
  }

  deleteLeave(resourceId: string, id: string) {
    resourceId = this.safeEncode(resourceId);
    return this.http.delete(`resources/${resourceId}/leave/${id}`);
  }

  newLeave(resourceId: string, leave: ResourceLeaveDto): Observable<ResourceLeaveDto> {
    resourceId = this.safeEncode(resourceId);
    return this.http.post<ResourceLeaveDto>(`resources/${resourceId}/leave`, leave);
  }

  resourceFactory(): ResourceMasterDto {
    return {
      active: true,
      code: "",
      statusId: "A",
      calendarId: "",
      useCalendar: true,
      jobTitle: "",
      rateId: "",
      supplierAccount: "",
      orderAccount: "",
      warrantyAccount: "",
      export: false,
      vatRegistered: false,
      vatCode: "",
      manager: "",
      controller: "",
      priceList: "",

      contact: newCustomerContact(),
      address: newAddress(),
      postalRatings: [],
      skillRatings: [],
      typeRatings: [],
      warehouse: "",
      warehouse2: "",
      collections: false,
    };
  }

  createResource(resource: ResourceMasterDto) {
    return this.http.post<ResourceMasterDto>(`resources`, resource).pipe(
      tap(r => this.store.add(r)));
  }

  updateResource(resource: ResourceMasterDto) {
    return this.http.put<ResourceMasterDto>(`resources/${resource.code}`, resource).pipe(
      tap(r => this.store.update(r.code, r)));
  }
}
